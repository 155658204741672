// import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import React, { Component } from "react"


import AniLink from "gatsby-plugin-transition-link/AniLink";

import "../styles/style.scss"

class Services extends Component {
    
    render() {


        return (

        <Layout noHeader noFooter>

            <SEO title="Polygon Dynamics"/>
                <div className="headerInformation">
                    <div className="row row___max">
                        <div className="col col4">
                            <h2 className="logo"><AniLink fade duration={0.5} to="/">— Polygon Dynamics</AniLink></h2>
                        </div>
                        <div className="col col4">
                            <div className="row">
                                {true &&
                                  <>

                                    <p className="menuItem hidden_iphonePlus"><AniLink fade duration={0.5} to="/archive">Archive</AniLink></p>
                                  </>
                                }
                                <p className="menuItem">~</p>
                                <p className="menuItem"><AniLink fade duration={0.5} to="/fashion">Fashion</AniLink></p>
                                <p className="menuItem"><AniLink fade duration={0.5} to="/services">Services</AniLink></p>
                                <p className="menuItem"><AniLink fade duration={0.5} to="/information">Information</AniLink></p>
                            </div>
                        </div>
                    </div>
                </div>
            <div className="information">
                <div className="informationBg"></div>
                <div className="row row___max">
                    <div className="col col4">
                        <h3 className="title marginBottom">Services</h3>
                        <p className="texte marginBottom">For the brands we serve as for our own projects, we bring day after day,<br></br>high end solutions, products and services to the Web.<br></br>We could not do things differently; from your idea to something real.<br></br>Together, we can build a collaboration that lasts.</p>
                            <h3 className="title marginBottom">Experiences</h3>
                            <p className="texte marginBottom">We are creating visual experiences and motion practice through design, visual effects & augmented reality with a strong emphasis on R&D. We are making interactive brand experiences and virtual playgrounds useful, usable and beautiful -- In that order</p>

                            <h3 className="title marginBottom">Innovation</h3>
                            <p className="texte marginBottom">We rely on the state of the art technology currently provided by Google & Apple; but for the extra miles, we build our own. The first two products that we will release soon are:<br></br><br></br>→ Automated Annotation tool for MacOS & Web creating perfect synthetic images to feed CoreMl/Turi <br></br>→ Object detection on custom trained models to create edgy experiences</p><br></br>

                    </div>

                    <div className="col col4">
                        <h3 className="title marginBottom">Design & Motion</h3>
                        <p className="texte marginBottom">Interactive Experiences<br></br>Motion Graphics<br></br>Modeling<br></br>Texturing<br></br>Animation & physics simulations<br></br>User Experience Design<br></br>Visual Effects<br></br>Instagram filters</p>
                            <h3 className="title marginBottom">Engineering</h3>
                            <p className="texte marginBottom">Creative Development<br></br>Product & Service Development<br></br>Front End Development<br></br>Native Mobile Development<br></br>Augmented Reality<br></br>Virtual Reality<br></br>Rapid Prototyping</p>
                        <h3 className="title marginBottom">Content Management</h3>
                        <p className="texte marginBottom">Publishing & management of 3D/AR assests<br></br>Upload & distribute content to App & Web<br></br>Open Source SDKs using full capabilities of our solution (soon)</p>
                    </div>
                </div>
            </div>
            <div className="informationVideo">
                <video preload="buffer" playsInline loop muted autoPlay>
                    <source src="/Cloth_02.mp4" type="video/mp4"></source>
                </video>
            </div>

        </Layout>

        )
    }

}

export default Services
